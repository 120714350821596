<template>
  <div v-if="mounted" class="costs mb-24">
    <div>
      <table class="w-full table">
        <template v-for="(roomRequest, roomRequestIdx) in roomRequests">
          <template v-for="(offer) in [getOffer(roomRequest.id)]">
            <template v-if="!isNil(offer)">
              <template v-for="roomType in [roomTypeMap[selectedOffersMap[roomRequest.id].roomTypeId]]">
                <template v-for="ratePlan in [ratePlanMap[selectedOffersMap[roomRequest.id].ratePlanId]]">
                  <tr>
                    <td class="pb-16" :class="[{ 'pt-16 border-t border-grayLight': (roomRequestIdx > 0)}]">
                      <div><span class="font-semibold">{{ roomType.name[locale] }}</span></div>
                      <div><span class="text-gray text-14">{{ ratePlan.name[locale] }}</span></div>
                    </td>
                    <td :class="[{ 'pt-16 border-t border-grayLight': (roomRequestIdx > 0)}]">
                      <span class="text-16 text-dark text-right block font-semibold whitespace-no-wrap">
                        {{ hotel.details.currency }}&nbsp;{{ offer.unitPriceSummary.amount + offer.unitPriceSummary.excludedTaxAmount + shoppingCartSummaryByRoomRequest[offer.roomRequestId].amount + shoppingCartSummaryByRoomRequest[offer.roomRequestId].excludedTaxAmount | toCurrencyDigits }}
                      </span>
                    </td>
                    <td class="w-24" :class="[{ 'pt-16 border-t border-grayLight': (roomRequestIdx > 0)}]">
                      <button v-show="hasDetailLines(offer)"
                              class="w-24 h-24 ml-6 flex items-center justify-center"
                              @click="toggleOffer(roomRequest.id)"
                      >
                        <chevron-down-icon size="18" class="text-dark"></chevron-down-icon>
                      </button>
                    </td>
                  </tr>
                  <!-- detail lines -->
                  <template v-if="hasDetailLines && showOfferDetails[roomRequest.id]">
                    <tr :key="ratePlan.id">
                      <td class="pb-16">
                        <div>
                          <span class="">{{ ratePlan.name[locale] }}</span>
                        </div>
                        <div>
                          <span class="text-gray text-14">
                            {{ hotel.details.currency }} {{ offer.unitPriceSummary.amount / offer.rates.length | toCurrency }} {{ $t('summary.perNight') }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span class="text-16 text-dark text-right block whitespace-no-wrap">
                          {{ hotel.details.currency }}&nbsp;{{ offer.unitPriceSummary.amount | toCurrencyDigits }}
                        </span>
                      </td>
                      <td class="w-24">
                        <info-icon v-tooltip="{ content: nightRates(offer) }"
                                   size="24"
                                   class="text-grayBlue cursor-help inline-block ml-6 relative -top-1"
                        ></info-icon>
                      </td>
                    </tr>
                    <template v-for="tax in offer.unitPriceSummary.taxDetails">
                      <template v-if="tax.isInclusive === false">
                        <tr :key="`ratePlan-${tax.taxId}`">
                          <td class="pb-16">
                            <div><span class="">{{ taxMap[tax.taxId].name[locale] }}</span></div>
                            <template v-if="taxMap[tax.taxId].percentage === false">
                              <span class="text-gray text-14">
                                {{ hotel.details.currency }}&nbsp;{{ taxMap[tax.taxId].amount | toCurrency }}
                                <template v-if="taxMap[tax.taxId].rateMode === 'PerPerson'">
                                  {{ $t('summary.perPerson') }}
                                </template>
                                <template v-if="taxMap[tax.taxId].rateFrequency === 'PerNight'">
                                  {{ $t('summary.perNight') }}
                                </template>
                                <template v-if="taxMap[tax.taxId].rateFrequency === 'PerStay'">
                                  {{ $t('summary.perStay') }}
                                </template>
                              </span>
                            </template>
                          </td>
                          <td>
                            <span class="text-16 text-dark text-right block whitespace-no-wrap">
                              {{ hotel.details.currency }}&nbsp;{{ tax.amount | toCurrencyDigits }}
                            </span>
                          </td>
                          <td class="w-24" />
                        </tr>
                      </template>
                    </template>
                    <!-- shopping cart -->
                    <template v-for="shoppingCartItem in shoppingCartByRoomRequest[roomRequest.id].shoppingCart">
                      <tr :key="shoppingCartItem.inHouseServiceId">
                        <td class="pb-16">
                          <div>
                            <span class=""><template v-if="shoppingCartItem.itemCount > 1">{{ shoppingCartItem.itemCount }} x </template>{{ inHouseServiceMap[shoppingCartItem.inHouseServiceId].name[locale] }}</span>
                          </div>
                          <span class="text-gray text-14">
                            {{ hotel.details.currency }}&nbsp;{{ shoppingCartItem.itemPrice | toCurrency }}
                            <template
                              v-if="inHouseServiceMap[shoppingCartItem.inHouseServiceId].rateMode === 'PerPerson'"
                            >
                              {{ $t('summary.perPerson') }}
                            </template>
                            <template
                              v-if="inHouseServiceMap[shoppingCartItem.inHouseServiceId].rateFrequency === 'PerNight'"
                            >
                              {{ $t('summary.perNight') }}
                            </template>
                            <!--                                  <template v-if="inHouseServiceMap[shoppingCartItem.inHouseServiceId].rateFrequency === 'PerStay'">-->
                            <!--                                    {{ $t('summary.perStay') }}-->
                            <!--                                  </template>-->
                          </span>
                        </td>
                        <td>
                          <span class="text-16 text-dark text-right block whitespace-no-wrap">
                            {{ hotel.details.currency }}&nbsp;{{ shoppingCartItem.unitPriceSummary.amount | toCurrencyDigits }}
                          </span>
                        </td>
                        <td class="w-24" />
                      </tr>
                      <template v-for="tax in shoppingCartItem.unitPriceSummary.taxDetails">
                        <template v-if="tax.isInclusive === false">
                          <tr :key="`shoppingCart-${shoppingCartItem.inHouseServiceId}-${tax.taxId}`">
                            <td class="pb-16">
                              <div><span class="">{{ taxMap[tax.taxId].name[locale] }}</span></div>
                            </td>
                            <td>
                              <span class="text-16 text-dark text-right block whitespace-no-wrap">
                                {{ hotel.details.currency }}&nbsp;{{ tax.amount | toCurrencyDigits }}
                              </span>
                            </td>
                            <td class="w-24" />
                          </tr>
                        </template>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>

        <template v-if="short">
          <tr>
            <td class="border-t-2 border-grayLight" colspan="3">
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td class="pb-16 pt-24 border-t-2 border-dark">
              <div>
                <span class="text-gray pr-16">{{ $t('summary.totalAmount') }}</span>
                <template v-if="hotel.details.google === true">
                  <br />
                  <span v-if="hotel.details.onlineBookingSettings.includingAllTaxesAndFees !== false" class="text-gray pr-16 text-10">{{ $t('summary.includingAllTaxesAndFees') }}</span>
                  <span v-else class="text-gray pr-16 text-10 inline-block">{{ hotel.details.onlineBookingSettings.additionalTaxesAndFees[locale]
                    || hotel.details.onlineBookingSettings.additionalTaxesAndFees[hotel.details.fallbackLanguage]
                    || hotel.details.onlineBookingSettings.additionalTaxesAndFees[hotel.details.primaryLanguage]
                    || $t('summary.includingAllTaxesAndFees') }}</span>
                </template>
              </div>
            </td>
            <td class="pt-24 border-t-2 border-dark">
              <span class="text-16 text-dark text-right block font-semibold whitespace-no-wrap">{{ hotel.details.currency }}&nbsp;{{ totalAmount | toCurrencyDigits }}</span>
            </td>
            <td class="border-t-2 border-dark w-24"></td>
          </tr>
          <template v-if="totalPrepaymentAmount > 0">
            <tr>
              <td class="pb-16">
                <div><span class="text-gray pr-16">{{ $t('summary.depositAmount') }}</span></div>
              </td>
              <td>
                <span class="text-16 text-dark text-right block font-semibold whitespace-no-wrap">{{ hotel.details.currency }}&nbsp;{{ totalPrepaymentAmount | toCurrencyDigits }}</span>
              </td>
              <td class="w-24"></td>
            </tr>
            <tr>
              <td class="pb-16">
                <div><span class="text-gray pr-16">{{ $t('summary.remainingAmount') }}</span></div>
              </td>
              <td>
                <span class="text-16 text-dark text-right block font-semibold whitespace-no-wrap">{{ hotel.details.currency }}&nbsp;{{ totalAmount - totalPrepaymentAmount | toCurrencyDigits }}</span>
              </td>
              <td class="w-24"></td>
            </tr>
          </template>
        </template>
      </table>
    </div>
  </div>
</template>
<script>
import { isEmpty, isNil } from 'lodash/lang';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { ChevronDownIcon, InfoIcon } from 'vue-feather-icons';
import { join } from 'lodash/array';

export default {
  name: 'CostSummary',

  components: {
    ChevronDownIcon,
    InfoIcon,
  },
  mixins: [BookingEngineMixin],
  props: {
    short: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      showOfferDetails: {},
      mounted: false,
    };
  },

  watch: {
    roomRequests: {
      deep: true,
      handler: function (newVal) {
        newVal.forEach((it) => {
          if (isNil(this.showOfferDetails[it.id])) {
            this.$set(this.showOfferDetails, it.id, false);
          }
        });
      },
    },
  },

  created() {
    this.roomRequests.forEach((it) => {
      this.$set(this.showOfferDetails, it.id, false);
    });
  },

  mounted() {
    this.mounted = true;
  },

  methods: {
    toggleOffer(roomRequestId) {
      if (isNil(this.showOfferDetails[roomRequestId])) {
        this.showOfferDetails[roomRequestId] = true;
      } else {
        this.showOfferDetails[roomRequestId] = !this.showOfferDetails[roomRequestId];
      }
    },
    hasDetailLines(offer) {
      return offer.unitPriceSummary.excludedTaxAmount > 0 || this.shoppingCartSummaryByRoomRequest[offer.roomRequestId].amount > 0 || this.shoppingCartSummaryByRoomRequest[offer.roomRequestId].excludedTaxAmount > 0;
    },
    isNil,
    isEmpty,
    nightRates(offer) {
      if (offer.rates.some((it) => it.amount !== it.originalAmount)) {
        return `<table>
${join(offer.rates.map((it, idx) => `<tr><td class="pr-16">${this.$t('summary.nthNight', [idx + 1])}</td><td>${this.hotel.details.currency}&nbsp;${this.$options.filters.toCurrencyDigits(it.amount)}</td><td class="pl-16 ${it.amount === it.originalAmount ? 'opacity-0' : ''}"><span class="line-through">${this.hotel.details.currency}&nbsp;${this.$options.filters.toCurrencyDigits(it.originalAmount)}</span></td></tr>`), '')}
</table>`;
      } else {
        return `<table>
${join(offer.rates.map((it, idx) => `<tr><td class="pr-16">${this.$t('summary.nthNight', [idx + 1])}</td><td>${this.hotel.details.currency}&nbsp;${this.$options.filters.toCurrencyDigits(it.amount)}</td></tr>`), '')}
</table>`;
      }

      /*
    return `<table>
${join(offer.rates.map((it, idx) => `<tr><td class="pr-16">${this.formatDate(it.effectiveDate, 'YYYYMMDD')}</td><td>{{ hotel.details.currency }}&nbsp;${this.$options.filters.toCurrencyDigits(it.amount)}</td></tr>`), '')}
</table>`;
     */
    },
  },
};
</script>
<style lang="scss">
@import '~@/styles/import';

.table {
  td {
    vertical-align: top;
  }
}

.costs {
  .table {
    table-layout: fixed;
  }
}

</style>
